import { useState, useRef, useMemo, useEffect } from "react";

import { useLocation,useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setTag } from "../../actions/tag";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';

import "./Register.scss";
import Geocode from "react-geocode";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import logo from "../../assets/images/logowhite.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Save from "@mui/icons-material/Save";
import EditIcon from '@mui/icons-material/Edit';

import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import tagService from "../../services/tag.service";
import Loader from "../../shared/components/loader/loader";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
import tokenService from "../../services/token.service";
import DeleteIcon from '@mui/icons-material/Delete';
import Popup from "../../shared/components/popup/Popup";
import authService from "../../services/auth.service";

const GOOGLE_MAPS_API_KEY = "AIzaSyDBw9x_XpFfJ3koJNai3Nsp7uCN8DZLFuI";
Geocode.setApiKey(GOOGLE_MAPS_API_KEY);

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const Register = () => {
  const token = tokenService.getLocalAccessToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tagId = useSelector(state => state.tag.tagInfo);
  const userName = tokenService.getUserName();
  const { user: currentUser } = useSelector((state) => state.auth);

  const location = useLocation();
  const isUpdate=location.pathname==='/update';
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isEditDestination, setIsEditDestination] = useState(false);
  const [completeRegistration, setCompleteRegistration] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPass, setConfirmPass] = useState('');
  const [matchPass, setMatchPass] = useState(false);
  const [openImagePopup, setOpenImagePopup] = useState(false);
  const [openPasswordPopup, setOpenPasswordPopup] = useState(false);
  const [deletedImage, setDeletedImage] = useState(false);
  const [photoLoader, setPhotoLoader] = useState(false);
  const [passLoader, setPassLoader] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [pswErr, setPswErr] = useState(false);
  const [statusMsg, setStatusMsg] = useState(null);
const [newPasswordData, setnewPasswordData] = useState({current_password:'',password:''});

    // google search

  const [address, setAddress] = useState(null);
  const [addressInputValue, setAddressInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [destinationValue, setDestinationValue] = useState(null);
  const [destinationInputValue, setDestinationInputValue] = useState("");
  const [options2, setOptions2] = useState([]);
  const loaded = useRef(false);
  const textInput = useRef(null);

  if(isUpdate) {
    if(!currentUser || userName.toLowerCase()!==tagId.userName.toLowerCase()) {
      navigate('/login');
    }
  }
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (autocompleteService && !autocompleteService.current && window.google && window.google.maps.places && window.google.maps.places.AutocompleteService) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (addressInputValue === "") {
      setOptions(address ? [address] : []);
      return undefined;
    }

    fetch({ input: addressInputValue }, results => {
      if (active) {
        let newOptions = [];

        if (address) {
          newOptions = [address];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [address, addressInputValue, fetch]);

  useEffect(() => {
    let active = true;

    if (autocompleteService && !autocompleteService.current && window.google && window.google.maps.places && window.google.maps.places.AutocompleteService) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (destinationInputValue === "") {
      setOptions2(destinationValue ? [destinationValue] : []);
      return undefined;
    }

    fetch({ input: destinationInputValue }, results => {
      if (active) {
        let newOptions = [];

        if (destinationValue) {
          newOptions = [destinationValue];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions2(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [destinationValue, destinationInputValue, fetch]);

  // end google search

  const transports = ["Plane", "Car", "Bus", "Ship"];
  const [step, setStep] = useState(isUpdate?2:1);
  const [data, setData] = useState({
    IsUpdateMethod: isUpdate,
    UserGUID:'',
    ProfilePhoto: "",
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber1: "",
    PhoneNumber2: "",
    Address: "",
    AddressLat: "",
    AddressLong: "",
    Note: "",
    isBlockedTag: false,
    UserName: "defaultUsername",
    Password: "",
    ImageName:'',
    ImageFile:null,
    TravelInfo: {
      TravelDestination: "",
      TravelDestionationLat: "",
      TravelDestionationLong: "",
      TransportType: "",
      AccomodationName: "",
      AccomodationPhoneNumber: "",
      Note: "",
      DateFrom: null,
      DateTo: null
    }
  });

  useEffect(() => {
    if(isUpdate){
      setData({...data,ImageName:tagId.profilePhoto?tagId.profilePhoto.name:'',UserGUID:tagId.userId, FirstName:tagId.firstName?tagId.firstName:'',LastName:tagId.lastName?tagId.lastName:'',Email:tagId.email?tagId.email:'',PhoneNumber1:tagId.phoneNumber1?tagId.phoneNumber1:'',PhoneNumber2:tagId.phoneNumber2?tagId.phoneNumber2:'',Address:tagId.address?tagId.address:'',AddressLat:tagId.addressLat?tagId.addressLat:'',AddressLong:tagId.addressLong?tagId.addressLong:'',Note:tagId.note?tagId.note:'',isBlockedTag:tagId.isBlockedTavelTag?tagId.isBlockedTavelTag:'',UserName:tagId.userName?tagId.userName:'',TravelInfo: {
        TravelDestination: tagId.travelInfo && tagId.travelInfo.travelDestionation? tagId.travelInfo.travelDestionation:'',
        TravelDestionationLat: tagId.travelInfo && tagId.travelInfo.travelDestinationLat? tagId.travelInfo.travelDestinationLat:'',
        TravelDestionationLong: tagId.travelInfo && tagId.travelInfo.travelDestinationLong? tagId.travelInfo.travelDestinationLong:'',
        TransportType: tagId.travelInfo && tagId.travelInfo.typeOfTransports? tagId.travelInfo.typeOfTransports.charAt(0).toUpperCase() + tagId.travelInfo.typeOfTransports.slice(1):'',
        AccomodationName: tagId.travelInfo && tagId.travelInfo.nameOfAccommodation? tagId.travelInfo.nameOfAccommodation:'',
        AccomodationPhoneNumber: tagId.travelInfo && tagId.travelInfo.contactNumberOfAccommodation? tagId.travelInfo.contactNumberOfAccommodation:'',
        Note: tagId.travelInfo && tagId.travelInfo.note? tagId.travelInfo.note:'',
        DateFrom: tagId.travelInfo? dayjs(tagId.travelInfo.dateFrom) : '',
        DateTo: tagId.travelInfo? dayjs(tagId.travelInfo.dateTo): '',
      }})
      
    }
  }, [tagId]);

  const today = new Date();
  const theme = createTheme({
    typography: {
      fontFamily: ["poppins"].join(",")
    }
  });
  const nextStep = () => {
    if(!isUpdate && (data.Password!==confirmPass || data.Password==='')){
      setMatchPass(true)
      return;
    }

    setStep(2);
    setData({...data,UserName:tagId.userName,UserGUID:tagId.userId})
    window.scrollTo(0, 0);
  };

  const handleFile=(e)=>{
    setData({...data,ImageFile:e.target.files[0]})
  }
  const handleChangeInputs=(e,isTravel)=>{
    if(e.target.name==="Password"){
      if(e.target.value===confirmPass){
        setMatchPass(false)
      }
    }
    const name=e.target.name;
    const value=e.target.value;
    const copyData={...data};
    isTravel ? copyData.TravelInfo[name]=value:copyData[name]=value;
    setData(copyData)
  }
  const handleCPass=(e)=>{
    if(e.target.value===data.Password){
      setMatchPass(false)
    }
    setConfirmPass(e.target.value)
  }
  const handleAddress=(address,isAddress)=>{
    const copyData={...data}
    isAddress ? (copyData.Address=address.description):(copyData.TravelInfo.TravelDestination=address.description);
        
    Geocode.fromAddress(address.description).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          if(isAddress){
            copyData.AddressLat=String(lat);
            copyData.AddressLong=String(lng);
          }else{
            copyData.TravelInfo.TravelDestionationLat=String(lat);
            copyData.TravelInfo.TravelDestionationLong=String(lng);
          }

          setData(copyData)
        },
        (error) => {
          console.error(error);
        }
      );
  }

  const handleCheckBox=(e)=>{
      setData({...data,isBlockedTag:e.target.checked})
  }

  const handleSkip = () => { 
    dispatch(setTag(null))
    navigate('/') 
  }

  const handleSubmit=()=>{

    const copyData={...data};
    const copyTI={...copyData.TravelInfo}
    if(copyTI.DateFrom){
      const dayFormatFrom=copyTI.DateFrom.get('date')<10?'0'+ copyTI.DateFrom.get('date'):copyTI.DateFrom.get('date');
      copyTI.DateFrom=dayFormatFrom +'-'+(copyTI.DateFrom.get('month')+1)+'-'+copyTI.DateFrom.get('year');
    }
    if(copyTI.DateTo){
      const dayFormatTo=copyTI.DateTo.get('date')<10?'0'+copyTI.DateTo.get('date'):copyTI.DateTo.get('date');
      copyTI.DateTo=dayFormatTo +'-'+(copyTI.DateTo.get('month')+1)+'-'+copyTI.DateTo.get('year');
    }
    

    copyData.TravelInfo=copyTI;

  var bodyFormData = new FormData();
    Object.keys(copyData).forEach(key => {
      if(key!=='TravelInfo'){
        bodyFormData.append(key, copyData[key]);
      }
  })
  Object.keys(copyData.TravelInfo).forEach(key => {
      bodyFormData.append('TravelInfo['+key+']', copyData.TravelInfo[key]);
})
    setRequestLoading(true)
    if(isUpdate){
      tagService.updateTag(bodyFormData,token).then((response)=>{
        setCompleteRegistration(true)
        tagService.getTagInfo(response.data.userId).then(response => {          
          dispatch(setTag(response.data))
          setTimeout(() => {
            setCompleteRegistration(false)
            setRequestLoading(false)
            navigate('/?guid=' + tagId.userId)
          }, 5000)
         });
        

      }).catch(err => setRequestLoading(false)      );
    }else{
      tagService.createTag(bodyFormData).then((response)=>{
        setCompleteRegistration(true)
        tagService.getTagInfo(response.data.userId).then(response => {          
          dispatch(setTag(response.data))
          setTimeout(() => {
            setCompleteRegistration(false)
            setRequestLoading(true)
            navigate('/?guid=' + tagId.userId)
          }, 5000)
        });
        

      }).catch(err => setRequestLoading(false) );
    }
  }

  const handleDate=(value, isFrom)=>{
    const copyData={...data};
    if(isFrom){
      if(value>data.TravelInfo.DateTo || Number.isNaN(data.TravelInfo.DateTo.get('day'))){
        copyData.TravelInfo.DateTo=value;
      }

      copyData.TravelInfo.DateFrom=value;
    }else{
      copyData.TravelInfo.DateTo=value;
      setTimeout(() => {
        textInput.current.focus();
      }, 100);
    }
    console.log(data.TravelInfo.DateTo);
    console.log(data.TravelInfo.DateFrom);

    setData(copyData)
  }

  const deletePhoto=()=>{
    setPhotoLoader(true)
    tagService.deleteProfilePhoto(tagId.userId,token).then(()=>{
      setData({...data,ImageFile:null})
      setDeletedImage(true)
      setPhotoLoader(false)
      setOpenImagePopup(false);
    })

  }

  const handleNewPassword=(e)=>{
    const name=e.target.name;
    const value=e.target.value;
    const copyPass={...newPasswordData};
    copyPass[name]=value;
    if(copyPass.current_password!==''&&copyPass.password!==''){
      setPswErr(false)
    }
    setnewPasswordData(copyPass);
  }

  const submitNewPassword=()=>{
    setStatusMsg(null)
    if(newPasswordData.current_password===''|| newPasswordData.password===''){
      setPswErr(true);
      return;
    }
    setPassLoader(true)
    let data={...newPasswordData,user_name:tagId.userName}
    authService.changePassword(data,token).then((response)=>{
      setPassLoader(false);
      setnewPasswordData({current_password:'',password:''})
      setPasswordChanged(true)
    }).catch((err)=>{
      setPassLoader(false);
      setStatusMsg(err.response.data.Message)
    })
  }

  const resetTravelInfo=()=>{
    setData({...data,    TravelInfo: {
      TravelDestination: "",
      TravelDestionationLat: "",
      TravelDestionationLong: "",
      TransportType: "",
      AccomodationName: "",
      AccomodationPhoneNumber: "",
      Note: "",
      DateFrom: null,
      DateTo: null
    }})
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    {tagId && <div className="register">
    {openPasswordPopup && (
      <Popup
        closePopup={() => {
          setOpenPasswordPopup(false);
          setnewPasswordData({current_password:'',password:''})
          setShowOldPassword(false);
          setShowNewPassword(false);
          setPasswordChanged(false)
        }}
      >
      <div className="popup-holder">
        <h2>Change password</h2>
        {passwordChanged && <p>Password is changed</p>}
        {!passwordChanged && <>
          <div className="password-form-holder">
          <div className="password-container">
          <TextField
          className="custom-input mb15"
          size=""
          id="oldPassword"
          label="Current password"
          variant="outlined"
          type={showOldPassword ? 'text' : 'password'}
          autoComplete="on"
          fullWidth
          name="current_password"
          onChange={handleNewPassword}
          value={newPasswordData.current_password}
        />
        <div className="icon" onClick={() => setShowOldPassword(!showOldPassword)}>
        {showOldPassword ? <VisibilityOffIcon/> : <Visibility/>} 
        
       </div>
          </div>
          <div className="password-container">
        <TextField
        className="custom-input mb15"
        size=""
        id="newPassword"
        label="New password"
        variant="outlined"
        type={showNewPassword ? 'text' : 'password'}
        autoComplete="on"
        fullWidth
        name="password"
        onChange={handleNewPassword}
        value={newPasswordData.password}
      />
      <div className="icon" onClick={() => setShowNewPassword(!showNewPassword)}>
      {showNewPassword ? <VisibilityOffIcon/> : <Visibility/>} 
      
     </div>
      </div>
      {pswErr && <p className='err-msg small-text'>Both fields are required</p>}
      </div>
      {statusMsg && <p className="status-msg">{statusMsg}</p>}
    <Button
    onClick={submitNewPassword}
    variant="contained"
    className="custom-btn mrr-10"
    color="primary"
  >
  {!passLoader && <span>Save</span>}
  {passLoader && <CircularProgress size="25px" color="info"/>} 
  </Button>
      </>
        }

      </div>
      </Popup>
    )}
    {openImagePopup && (
      <Popup
        closePopup={() => {
          setOpenImagePopup(false);
        }}
      >
      <div className="popup-holder">
        <h2>Are you sure you want to delete the photo?</h2>
        <Button
        onClick={deletePhoto}
        variant="contained"
        className="custom-btn mrr-10"
        color="primary"
      >
        {!photoLoader && <span>Yes</span>}
        {photoLoader && <CircularProgress size="25px" color="info"/>} 
      </Button>
      <Button
      onClick={()=>{setOpenImagePopup(false);}}
      variant="contained"
      className="custom-btn"
      color="info"
    >
      No
    </Button>
      </div>
      </Popup>
    )}
      <div className="top-content" style={{ backgroundImage: `url(${  tagId?.logo_img ? tagId?.logo_img : 'linear-gradient(0deg, #003399 0%, #3a79fa 100%)'})` }}>
        <div className="top-wrapper">
              <div className="logo-holder">
                {!tagId?.logo_img ?
                    <img src={logo} alt="brand" /> : null
                }
              </div>
        </div>
      </div>
      <div className="card-holder">
        <Card className="form-card">
        <h1>{step === 1 ? "Owner's info" : "TRAVEL info"}</h1>
          <div className="steps">
            <div className={`step ${step === 1 && "active"}`} onClick={()=>{setStep(1)}}>
              <p>1</p>
            </div>
            <div className="step-line"></div>
            <div className={`step ${step === 2 && "active"}`} onClick={()=>{isUpdate && setStep(2)}}>
              <p>2</p>
            </div>
          </div>
          {step === 1 && (
            <form>
              <ThemeProvider theme={theme}>
                <div className="file-input">
                  <p>Profile Image</p>
                  {isUpdate && tagId.profilePhoto && !data.ImageFile && !deletedImage &&<div className="profile-img-holder">
                  <div className="delete-sign" onClick={()=>{setOpenImagePopup(true)}}><DeleteIcon/></div>
                  <img src={tagId.profilePhoto.uri}/>
                  </div>}
                  <input type="file" variant="outlined" onChange={handleFile} />
                </div>
                <FormControlLabel
                control={
                  <Checkbox
                  onChange={handleCheckBox}
                    sx={{
                      color: "#003399",
                      "&.Mui-checked": {
                        color: "#003399"
                      }
                    }}
                    checked={data.isBlockedTag}
                  />
                }
                label="Block travel tag"
              />
              <p className="info-text">
                *When blocked, travel information are hidden
              </p>

                <TextField
                  className={`custom-input ${data.FirstName.length === 0 ? "required" : ""}`}
                  size=""
                  id="first-name"
                  label="First name"
                  variant="outlined"
                  name="FirstName"
                  value={data.FirstName}
                  fullWidth
                  onChange={(e)=>{handleChangeInputs(e,false)}}
                />
                <TextField
                  className="custom-input"
                  size=""
                  id="last-name"
                  label="Last name"
                  variant="outlined"
                  fullWidth
                  name="LastName"
                  onChange={(e)=>{handleChangeInputs(e,false)}}
                  value={data.LastName}
                />
                <TextField
                  className={`custom-input ${data.Email.length === 0 ? "required" : ""}`}
                  size=""
                  id="Email"
                  label="Email"
                  variant="outlined"
                  type="email"
                  fullWidth
                  name="Email"
                  onChange={(e)=>{handleChangeInputs(e,false)}}
                  value={data.Email}
                />
                <TextField
                  className="custom-input"
                  size=""
                  id="Phone number"
                  label="Phone number"
                  variant="outlined"
                  fullWidth
                  name="PhoneNumber1"
                  onChange={(e)=>{handleChangeInputs(e,false)}}
                  value={data.PhoneNumber1}
                />
                <TextField
                  className="custom-input"
                  size=""
                  id="Phone number 2"
                  label="Phone number"
                  variant="outlined"
                  fullWidth
                  name="PhoneNumber2"
                  onChange={(e)=>{handleChangeInputs(e,false)}}
                  value={data.PhoneNumber2}
                />
                {isUpdate && !isEditAddress && data.Address && <div className="address-holder">
                  <div>
                    <p>Address:</p> 
                    <p>{data.Address}<span onClick={()=>{setIsEditAddress(true)}}><EditIcon /></span></p>
                  </div>
                </div>}
                {!isUpdate || (isUpdate && isEditAddress) || (isUpdate && !data.Address)?
                (<Autocomplete
                  className="custom-input"
                  id="google-map-demo"
                  noOptionsText=''
                  fullWidth
                  getOptionLabel={option =>
                    typeof option === "string" ? option : option.description
                  }
                  filterOptions={x => x}
                  options={options}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={address}
                  onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    handleAddress(newValue,true)
                    setAddress(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setAddressInputValue(newInputValue);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Address" fullWidth />
                  )}
                  renderOption={(props, option) => {
                    const matches =
                      option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map(match => [
                        match.offset,
                        match.offset + match.length
                      ])
                    );

                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Box
                              component={LocationOnIcon}
                              sx={{ color: "text.secondary", mr: 2 }}
                            />
                          </Grid>
                          <Grid item xs>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400
                                }}
                              >
                                {part.text}
                              </span>
                            ))}

                            <Typography variant="body2" color="text.secondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />):null}
                <TextField
                  className="custom-input"
                  placeholder="Note"
                  multiline
                  rows={2}
                  fullWidth
                  name="Note"
                  onChange={(e)=>{handleChangeInputs(e,false)}}
                  value={data.Note}
                  inputProps={{
                    maxLength: 200
                  }}
                />

                <div> <p className="username-holder">
                  Username:<span> {tagId?tagId.userName:null}</span>
                </p>
                {isUpdate && <Button className="custom-btn-outlined" onClick={()=>{setOpenPasswordPopup(true)}}>Change password</Button>}
                { !isUpdate && <>
                  
                  <div className="password-container">
                  <TextField
                  className={`custom-input mb15 ${data.Password.length === 0 ? "required" : ""}`}
                  size=""
                    id="Password"
                    label="Password"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="on"
                    fullWidth
                    name="Password"
                    onChange={(e)=>{handleChangeInputs(e,false)}}
                    value={data.Password}
                  />
                   <div className="icon" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOffIcon/> : <Visibility/>} 
                    
                   </div>
  
                  </div>
                  <div>
                  <div className="password-container">
                  <TextField
                  className={`custom-input mb15 ${confirmPass.length === 0 ? "required" : ""}`}
                  size=""
                    id="ConfirmPassword"
                    label="Confirm password"
                    variant="outlined"
                    type={showConfirmPassword ? 'text' : 'password'}
                    autoComplete="on"
                    value={confirmPass}
                    onChange={handleCPass}
                    fullWidth
                  /> 
                  <div className="icon" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <VisibilityOffIcon/> : <Visibility/>} </div>
                  </div>
                  {matchPass&& <div className='err-msg'>Passwords doesn't match</div>}
                  </div>
                  </> }

                </div>
              
              </ThemeProvider>
              <Button
                onClick={nextStep}
                variant="contained"
                fullWidth
                disabled={(data.Email && data.FirstName) ? false : true}
                className="custom-btn"
                endIcon={<KeyboardDoubleArrowRightIcon className="svg_icons" />}
              >
                Next
              </Button>
            </form>
          )}
          {step === 2 && (
            <form>
              <div className="info-holder">
                <p>
                  Please enter travel information in order for you luggage to be
                  more secure!
                </p>
                <p>(Change every time you travel)</p>
              </div>

              <ThemeProvider theme={theme}>
              <Button className="custom-btn-outlined" onClick={resetTravelInfo}>Reset Travel Information</Button>
              {isUpdate && !isEditDestination && data.TravelInfo.TravelDestination && <div className="address-holder">
              <div>
                <p>Travel Destination</p> 
                <p>{data.TravelInfo.TravelDestination}<span onClick={()=>{setIsEditDestination(true)}}><EditIcon /></span></p>
              </div>
            </div>}
            {!isUpdate || (isUpdate && isEditDestination) || (isUpdate && !data.TravelInfo.TravelDestination)? 
          (<Autocomplete
                  className="custom-input"
                  id="google-map-demo"
                  noOptionsText=''
                  fullWidth
                  getOptionLabel={option =>
                    typeof option === "string" ? option : option.description
                  }
                  filterOptions={x => x}
                  options={options2}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={destinationValue}
                  onChange={(event, newValue) => {
                    setOptions2(newValue ? [newValue, ...options2] : options2);
                    handleAddress(newValue,false)
                    setDestinationValue(newValue);
                
                  }}
                  onInputChange={(event, newInputValue) => {
                    setDestinationInputValue(newInputValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Travel destination"
                      fullWidth
                    />
                  )}
                  renderOption={(props, option) => {
                    const matches =
                      option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map(match => [
                        match.offset,
                        match.offset + match.length
                      ])
                    );

                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item>
                            <Box
                              component={LocationOnIcon}
                              sx={{ color: "text.secondary", mr: 2 }}
                            />
                          </Grid>
                          <Grid item xs>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400
                                }}
                              >
                                {part.text}
                              </span>
                            ))}

                            <Typography variant="body2" color="text.secondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />):null}

                <FormControl fullWidth className="custom-input">
                    <InputLabel id="demo-simple-select-label">Type of transportation</InputLabel>
                    <Select
                        fullWidth
                        className="custom-input"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data.TravelInfo.TransportType}
                        label="Type of transportation"
                        onChange={(e)=>{handleChangeInputs(e,true)}}
                        name="TransportType"
                    >
                    {transports.map((item)=><MenuItem key={item} value={item}>{item}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField
                  className="custom-input"
                  size=""
                  id="accommodation"
                  label="Name of accommodation"
                  variant="outlined"
                  fullWidth
                  name="AccomodationName"
                  onChange={(e)=>{handleChangeInputs(e,true)}}
                  value={data.TravelInfo.AccomodationName}
                />
                <TextField
                  inputRef={textInput}
                  className="custom-input"
                  size=""
                  id="accommodation-number"
                  label="Contact number of accommodation"
                  variant="outlined"
                  fullWidth
                  name="AccomodationPhoneNumber"
                  onChange={(e)=>{handleChangeInputs(e,true)}}
                  value={data.TravelInfo.AccomodationPhoneNumber}
                />               
                <Stack spacing={3}>
                  <DesktopDatePicker
                  className="custom-date"
                  minDate={today}
                  label="From"
                  inputFormat="MM/DD/YYYY"
                  value={data.TravelInfo.DateFrom}                
                  onChange={(e)=>{handleDate(e,true)}}
                  renderInput={(params) => <TextField name="DateFrom" {...params} fullWidth/>}
                  />
                  <DesktopDatePicker
                  className="custom-date"
                  minDate={data.TravelInfo.DateFrom}
                  label="To"
                  inputFormat="MM/DD/YYYY"
                  value={data.TravelInfo.DateTo}
                  onChange={(e)=>{handleDate(e,false)}}
                  renderInput={(params) => <TextField name="DateFrom" {...params} fullWidth/>}
                  />
                </Stack>

                <TextField
                  className="custom-input"
                  placeholder="Note"
                  multiline
                  rows={2}
                  fullWidth
                  name="Note"
                  onChange={(e)=>{handleChangeInputs(e,true)}}
                  value={data.TravelInfo.Note}
                />
              </ThemeProvider>
              <Button
                onClick={handleSubmit}
                variant="contained"
                fullWidth
                className="custom-btn"
                endIcon={<KeyboardDoubleArrowRightIcon className="svg_icons" />}
              >
                Skip
              </Button>
              <Button
                variant="contained"
                fullWidth
                className="custom-btn secondary"
                endIcon={!requestLoading ? <Save className="svg_icons" /> : null}
                onClick={handleSubmit}
              >
                {!requestLoading && <span>Save</span>} 
               {requestLoading && <CircularProgress size="25px"/>} 
              </Button>
            </form>
          )}
        </Card>
      </div>
    </div>}
    {!tagId && <Loader/>}
    {completeRegistration && <Loader successMessage={true}/>}
    </LocalizationProvider>
  );
};

export default Register;
