import axios from "axios";

const URL="https://travelinfoapi.azurewebsites.net/api"

export const instance = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "multipart/form-data" ,
    "Access-Control-Allow-Origin": "*"
  },
});

export const loginInstance = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/JSON" ,
    "Access-Control-Allow-Origin": "*"
  },
});
