import {loginInstance as api} from "./api";
import TokenService from "./token.service";

const login = (userName, password) => {
    return api
      .post("/authentication/login", {
        userName,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data,userName);
        }

        return response.data;
      });
};

const forgotPassword = (userName) => {
  return api
    .post("/authentication/forgot-password", {
      userName
    })
    .then(response => {
        return response.data;
    });
  };

  const resetPassword = (token, username, password, confirmPassword) => {
    return api
      .post(`/authentication/reset-password?token=${token}&username=${username}&password=${password}&confirmPassword=${confirmPassword}`)
      .then(response => {
          return response.data;
      });
    };

  const logout = () => {
    TokenService.removeUser();
  };

  
  const register = (username, email, password) => {
    return api.post("/authentication/register-user", {
      username,
      email,
      password
    });
  }

  const changePassword=(data,token)=>api.post('/authentication/chenge-password?user_name='+data.user_name+'&current_password='+data.current_password+'&password='+data.password,null,{headers:{'Authorization': 'Bearer '+token}});


export default {
  register,
  forgotPassword,
  resetPassword,
  login,
  logout,
  changePassword
};
