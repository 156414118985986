import { SET_TAG } from "../actions/types";

const initialState = {
    tagInfo: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_TAG:
      return { tagInfo: payload };

    default:
      return state;
  }
}