import { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "../../shared/components/Map/Map";
import image1 from "../../assets/images/min/1.jpg";
import image2 from "../../assets/images/min/2.jpg";
import image3 from "../../assets/images/min/3.jpg";
import image4 from "../../assets/images/min/4.jpg";
import image5 from "../../assets/images/min/5.jpg";
import footerImage from "../../assets/images/footer.png";
import logo from "../../assets/images/logowhite.png";
import logoBlack from "../../assets/images/logoblack.png";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import dayjs from "dayjs";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../../actions/auth";

import DirectionsCarFilledRoundedIcon from "@mui/icons-material/DirectionsCarFilledRounded";
import DirectionsBusRoundedIcon from "@mui/icons-material/DirectionsBusRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DirectionsBoatRoundedIcon from "@mui/icons-material/DirectionsBoatRounded";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import PhonelinkOffIcon from "@mui/icons-material/PhonelinkOff";

import "./Home.scss";
import Popup from "../../shared/components/popup/Popup";
import tokenService from "../../services/token.service";
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';

const Home = () => {
  const token = tokenService.getLocalAccessToken();
  const userName = tokenService.getUserName();
  const user = tokenService.getUser();

  const navigate = useNavigate();

  const [openPopup, setOpenPopup] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const tagInfo = useSelector((state) => state.tag.tagInfo);
  const pictureArray = [image1, image2, image3, image4, image5];
  const randomIndex = useMemo(
    () => Math.floor(Math.random() * pictureArray.length),
    []
  );
  const selectedPicture = pictureArray[randomIndex];
  const dispatch = useDispatch();
  const redirectToHomePage = () => {
    navigate("/");
    window.location.reload();
  };

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  
  return tagInfo ? (
    <>
      {tagInfo.isBlockedTavelTag && user?.userId !== tagInfo.userId ? (
        <div className="blocked-tag-wrapper">
          <PhonelinkOffIcon />
          <h2>Private Mode is activated.</h2>
          <p>Information about the user can't be shown at the moment.</p>
          <img
            onClick={() => {
              navigate("/login");
              }}
           src={logo} alt="travel tag" />
        </div>
      ) : (
        <div>
          {openPopup && (
            <Popup
              closePopup={() => {
                setOpenPopup(false);
              }}
            >
              {tagInfo.profilePhoto ? (
                <img src={tagInfo?.profilePhoto?.uri} alt="profile" />
              ) : null}
            </Popup>
          )}

          <div
            className="cover-image"
            style={{ backgroundImage: `url(${tagInfo?.logo1_img ? tagInfo?.logo1_img : selectedPicture})` }}
          >
            {!token && (
              <div
                className="top-btn"
                onClick={() => {
                  navigate("/login");
                }}
              >
                <LoginIcon />
              </div>
            )}
            {/* {token && (
              <div className="top-btn" onClick={logOut}>
                <LogoutIcon />
              </div>
            )} */}
            <div
              className="edit-icon"
              onClick={() => {
                currentUser && tagInfo.userName.toLowerCase() === userName.toLowerCase()
                  ? navigate("/update")
                  : navigate("/login");
              }}
            >
              <EditRoundedIcon />
            </div>
          </div>
          {tagInfo ? (
            <div className="detail">
              <div className="main-info">
                {tagInfo.profilePhoto ? (
                  <div
                    className="profile-image"
                    onClick={() => {
                      setOpenPopup(true);
                    }}
                  >
                    {tagInfo.profilePhoto && (
                      <img src={tagInfo?.profilePhoto?.uri} alt="profile" />
                    )}
                  </div>
                ) : null}
                {tagInfo.userName &&                 
                  <div className={`user-id-holder ${tagInfo.profilePhoto && 'have-img'}`}>
                    <p>{tagInfo.userName}</p>
                  </div>}

                <h1 className={!tagInfo.userName? 'top-space':''}>
                  {tagInfo.firstName ? tagInfo.firstName : ""}{" "}
                  {tagInfo.lastName ? tagInfo.lastName : ""}
                </h1>
                {(tagInfo.phoneNumber1 || tagInfo.phoneNumber2) && (
                  <>
                    <div className="phone-holder">
                      {tagInfo.phoneNumber1 && (
                        <div>
                          <a href={"tel:" + tagInfo.phoneNumber1}>
                            <div>
                              <div>
                                <LocalPhoneIcon />
                              </div>
                              <span>{tagInfo.phoneNumber1}</span>
                            </div>
                          </a>
                        </div>
                      )}

                      {tagInfo.phoneNumber2 && (
                        <div>
                          <a href={"tel:" + tagInfo.phoneNumber2}>
                            <div className="phone-two">
                              <div>
                                <LocalPhoneIcon />
                              </div>
                              <span>{tagInfo.phoneNumber2}</span>
                            </div>
                          </a>
                        </div>
                      )}
                    </div>
                    <p className="phoneInfo">Click on number to call</p>
                  </>
                )}
                {tagInfo.note && (
                  <div className="note-holder">
                    <label>Note</label>
                    <div className="note">{tagInfo.note}</div>
                  </div>
                )}
                {tagInfo.addressLat && tagInfo.addressLong && (
                  <div className="address-holder">
                    <label>My Adress</label>
                    <p>{tagInfo.address}</p>
                    <div className="map">
                      <Map
                        lat={Number(tagInfo.addressLat)}
                        long={Number(tagInfo.addressLong)}
                      />
                    </div>
                  </div>
                )}
              </div>

              {tagInfo.travelInfo &&
                (tagInfo.travelInfo.nameOfAccommodation ||
                  tagInfo.travelInfo.dateFrom ||
                  tagInfo.travelInfo.dateTo ||
                  tagInfo.travelInfo.contactNumberOfAccommodation) && (
                  <>
                    <div className="divider">
                      <div className="divider-icon">
                        <TravelExploreIcon />
                      </div>
                    </div>
                    <div className="additional-info">
                      <h2>Travel information</h2>
                      {tagInfo.travelInfo &&
                        tagInfo.travelInfo.nameOfAccommodation && (
                          <div className="info-holder address">
                            <label>I'm staying in</label>
                            <div className="info">
                              {tagInfo.travelInfo.nameOfAccommodation}
                            </div>
                          </div>
                        )}


                      {tagInfo.travelInfo.contactNumberOfAccommodation && (
                        <>
                          <div className="phone-holder">
                            <div>
                              <a
                                href={
                                  "tel:" +
                                  tagInfo.travelInfo
                                    .contactNumberOfAccommodation
                                }
                              >
                                <div>
                                  <div>
                                    <LocalPhoneIcon />
                                  </div>
                                  <span>
                                    {
                                      tagInfo.travelInfo
                                        .contactNumberOfAccommodation
                                    }
                                  </span>
                                </div>
                              </a>
                            </div>
                          </div>
                          <p className="phoneInfo">Click on number to call</p>
                        </>
                      )}
                      {tagInfo.travelInfo.note && (
                        <div className="note-holder">
                          <label>Note:</label>
                          <div className="note">{tagInfo.travelInfo.note}</div>
                        </div>
                      )}
                      <div className="datetranspotr-holder">
                      {tagInfo.travelInfo.dateFrom &&
                        tagInfo.travelInfo.dateTo && (
                          <div className="info-holder">
                            <label>Period of stay</label>
                            <div className="info daterange-holder">
                              {dayjs(tagInfo.travelInfo.dateFrom).format(
                                "DD.MM.YYYY."
                              )}<br></br>
                              - <br></br>
                              {dayjs(tagInfo.travelInfo.dateTo).format(
                                "DD.MM.YYYY."
                              )}
                            </div>
                          </div>
                        )}
                      {tagInfo.travelInfo.typeOfTransports && (
                        <div className="info-holder">
                          <label>Transportation</label>
                          <div className="info">
                            <div className="icon-holder">
                              {(tagInfo.travelInfo.typeOfTransports ===
                                "Plane" ||
                                tagInfo.travelInfo.typeOfTransports ===
                                  "Airplane") && (
                                <FlightTakeoffIcon color="69a49f" />
                              )}
                              {tagInfo.travelInfo.typeOfTransports ===
                                "Car" && (
                                <DirectionsCarFilledRoundedIcon color="69a49f" />
                              )}
                              {tagInfo.travelInfo.typeOfTransports ===
                                "Bus" && (
                                <DirectionsBusRoundedIcon color="69a49f" />
                              )}
                              {tagInfo.travelInfo.typeOfTransports ===
                                "Ship" && (
                                <DirectionsBoatRoundedIcon color="69a49f" />
                              )}
                            </div>
                            <span>
                              By {tagInfo.travelInfo.typeOfTransports}{" "}
                            </span>
                          </div>
                        </div>
                      )}
                      </div>
                      {tagInfo.travelInfo.travelDestinationLat &&
                        tagInfo.travelInfo.travelDestinationLong && (
                          <div className="address-holder">
                            <label>My Adress:</label>
                            <p>{tagInfo.travelInfo.travelDestionation}</p>

                            <div className="map">
                              <Map
                                lat={Number(
                                  tagInfo.travelInfo.travelDestinationLat
                                )}
                                long={Number(
                                  tagInfo.travelInfo.travelDestinationLong
                                )}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </>
                )}
                {tagInfo?.link ?
                    <div className="button-link" style={{zIndex: 100, position: 'relative'}}>
                            <a href={tagInfo?.link} target="_blank">{tagInfo?.linkText ? tagInfo?.linkText : 'Website'}</a>
                        </div> : null
                    }

              <img onClick={redirectToHomePage} className="footer-image" alt="footer" src={footerImage} />
            </div>
          ) : (
            <div>
              <p>Please scan your tag</p>
            </div>
          )}
        </div>
      )}
    </>
  ) : (
    <div className="landing-holder">
      <img src={logo} alt="travel tag" />

      {/* <h2>Contact us!</h2> */}
      
      
      <div
        className="top-btn"
        onClick={() => {
          navigate("/login");
        }}
      >
         <div className="login-button">
          <span>LOGIN</span>
          <LoginIcon />
        </div> 
      </div>

      <div className="contact-form">
       <div className="contact-line">
        <EmailIcon/> 
        <a href = "mailto: info@nfcinn.com">info@nfcinn.com</a>
        </div>
        <div className="contact-line">
       <CallIcon/> <a href="tel:+381654400117">+381 65-440-0117</a>
       </div>
      </div>
    </div>
  );
};

export default Home;
