import React from 'react';
import './404.scss'
import logo from '../../assets/images/logoblack.png'

const NotFound = () => {
    return (
        <div className="not-found">
            <div>
            <img src={logo}/>
            </div> 
            <h1>Page not found</h1>
            <p>404</p>
        </div>
    )
}

export default NotFound
