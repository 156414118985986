import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {loginInstance as api} from "../../services/api";

import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { login } from "../../actions/auth";
import { forgotPassword } from "../../actions/auth";
import logo from "../../assets/images/logowhite.png";
import Card from "@mui/material/Card";

import './Login.scss'
import TokenService from "../../services/token.service";
import TagService from "../../services/tag.service";
import { setTag } from "../../actions/tag";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const Login = () => {

  const navigate = useNavigate();
  const tagId = useSelector(state => state.tag.tagInfo)

  const form = useRef();

  const forgotFormRef = useRef();
  const forgotCheckBtn = useRef();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [statusMsg, setStatusMsg] = useState(null);
  
  const [forgotForm, setForgotForm] = useState(false);
  const [ succesMessage, setSuccesMessage ] = useState(false);
  const [requiredUsername, setRequiredUsername] = useState(false);
  const [requiredPassword, setRequiredPassword] = useState(false);
  const [requiredEmail, setRequiredEmail] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);

  const dispatch = useDispatch();


  const toogleForgotForm = () => {
    setForgotForm(forgotForm => !forgotForm);
  }

  const showSuccessMessage = () => {
    setSuccesMessage(succesMessage => !succesMessage);
  }

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
    checkValidation(username,setRequiredUsername)
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    if(email===''){
      setRequiredEmail(true)
    }else{
      setRequiredEmail(false)
    }
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    checkValidation(password,setRequiredPassword)
  };

  const checkValidation=(property,callback)=>{
    if(property===''){
      callback(true);
      return false;
    }else{
      callback(false);
      return true;
    }
  }

  const handleLogin = (e) => {
    e.preventDefault();
    setStatusMsg(null);
    if(checkValidation(password,setRequiredPassword) && checkValidation(username,setRequiredUsername)){
        setLoading(true);
        dispatch(login(username.toLowerCase(), password))
        .then((response) => {
          if(tagId){
            navigate('/update')
          }else{
            if (response.accessToken) {
              TokenService.setUser(response,username);
            }
            const guid=response.userId;
            TagService.getTagInfo(guid).then(response => {
              response.data.isPasswordSet?navigate('/?guid=' + guid):navigate('/register');
              dispatch(setTag(response.data))
             });
          }
        })
        .catch((err) => {
          setLoading(false);
          setStatusMsg(err.response.data.Message)
        });
      
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();

    setLoading(true);

    forgotFormRef.current.validateAll();

    if (forgotCheckBtn.current.context._errors.length === 0) {
      dispatch(forgotPassword(email))
        .then(() => {
          setLoading(false);
          showSuccessMessage();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
   <div className="login">
     <div className="top-content">
      {tagId && 
        <div className="edit-icon" onClick={() => {navigate("/?guid="+tagId.userId);}}>
        <ArrowBackIcon />
        </div>
      }
        <div className="top-wrapper">
          <div className="logo-holder">
            <img src={logo} />
          </div>
        </div>
      </div>
      <div className="card-holder">
        <Card className="form-card">
        <h1>{!forgotForm  ? "Login" : "Forgot password"}</h1>

        {!forgotForm ? (
  <Form onSubmit={handleLogin} ref={form}>
    <div className="form-group">
      <TextField
      type="text"
      className="custom-input"
      name="username"
      value={username}
      onChange={onChangeUsername}
      required
      fullWidth
      autoComplete="on"
      // variant="outlined"
      placeholder="Username"
      error={requiredUsername}
    />
    {requiredUsername&&<p className="error-text">Required field</p>}
    </div>
  <div className="form-group">
    <TextField
    type="password"
    className="custom-input"
    name="password"
    value={password}
    fullWidth
    onChange={onChangePassword}
    variant="outlined"
    placeholder="Password"
    error={requiredPassword}
  />
  {requiredPassword &&<p className="error-text">Required field</p>}
  </div>

  <div className="form-group">
    {statusMsg &&<p className="status-msg">{statusMsg}</p>}
    <Button type="submit" className="custom-btn" disabled={loading} fullWidth variant="contained" id="btn-login">
      {loading ? 
       <CircularProgress color="success"  style={{width: 20, height: 20, color: 'white'}}/>
        : <span>Login</span>
      }
    </Button>
  </div>
  <div id="link" className="linkStyle" onClick={toogleForgotForm}>Forgot password?</div>

</Form>

): 
<Form onSubmit={handleResetPassword} ref={forgotFormRef}>
  <div className="form-group">
    <TextField
      type="text"
      className="custom-input"
      name="email"
      value={email}
      onChange={onChangeEmail}
      variant="outlined"
      placeholder="Username"
      fullWidth
      size=""
      error={requiredEmail}
    />
    {requiredEmail  &&<p className="error-text">Required field</p>}
  </div>

  <div className="form-group">
  <Button type="submit" className="custom-btn" disabled={loading} fullWidth variant="contained" id="btn-login">
      {loading ? 
        <CircularProgress color="success"  style={{width: 20, height: 20, color: 'white'}}/>
      : <span>Send</span>
      }
    </Button>
  </div>
  <div id="link2" className="linkStyle" onClick={toogleForgotForm}>Back to login</div>

  <CheckButton style={{ display: "none" }} ref={forgotCheckBtn} />
</Form>
}

  
  {succesMessage && 
    <p className="successMesage">
      If the email is in database, we will send you url for password reset.
    </p>
  }
        </Card>
      </div>
   </div>
  );
};

export default Login;
