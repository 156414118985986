import {instance as api} from "./api";

const getTagInfo=(userGuid)=>api.get('/tag/getTagInfo?userGuid='+userGuid);
const createTag=(data)=>api.post('/tag/createTag',data);
const updateTag=(data,token)=>api.post('/tag/updateTag',data,{headers:{'Authorization': 'Bearer '+token}});
const deleteProfilePhoto=(userId,token)=>api.get('/tag/DeleteProfilePhoto?userId='+userId,{headers:{'Authorization': 'Bearer '+token}});

export default {
        getTagInfo,
        createTag,
        updateTag,
        deleteProfilePhoto
};