import React from 'react'
import './loader.scss'
import planet from '../../../assets/images/planet.gif'
const Loader = (props) => {
    return (
        <div className="background">
        <div className="loader-holder">
            {props.successMessage ? 
            <>
                <h2>Congrats!</h2>
                <p>Your travel tag configuration is completed!</p>
            </> : null
            }
            
        <div className="loader">
            <img src={planet} alt="planet"/>
        </div>
        {props.successMessage ? 
        <>
        <p>In order to see your travel tag information scan it with your phone</p>
        <p>Please check your email for username.</p>
        </>
        : null
        }
        </div>
        </div>

    )
}

export default Loader
