import { useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

export function Map(props) {
  const lat = props.lat;
  const long = props.long;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDBw9x_XpFfJ3koJNai3Nsp7uCN8DZLFuI',
  });
  if (!isLoaded) return <div>Loading...</div>;
  return <RenderMap lat={lat} long={long}/>;
}

function RenderMap(props) {
  const lat = props.lat;
  const long = props.long;  
  const center = useMemo(() => ({ lat: lat, lng: long }), []);
  return (
    
    <GoogleMap zoom={15} center={center}  mapContainerClassName="map-container">
      <Marker position={center} />
    </GoogleMap>
  );
}