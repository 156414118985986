class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.accessToken;
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
  getUserName() {
    return JSON.parse(localStorage.getItem("userName"));
  }

  setUser(user,userName) {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("userName", JSON.stringify(userName));
  }

  removeUser() {
    localStorage.removeItem("user");
    localStorage.removeItem("userName");
  }
}

export default new TokenService();
