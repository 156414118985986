import React, { useEffect, useCallback,useState } from "react";
import { Routes, Route, useLocation,useNavigate } from "react-router-dom";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";

import Login from "./Views/Login/Login";
import Reset from "./Views/Reset/Reset";

import Home from "./Views/Home/Home";

import EventBus from "./common/EventBus";
import { logout } from "./actions/auth";

import Register from "./Views/Register/Register";
import TagService from "./services/tag.service";
import { setTag } from "./actions/tag";
import Loader from "./shared/components/loader/loader";
import NotFound from "./Views/404/404";

const App = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const search = useLocation().search;
  const guid = new URLSearchParams(search).get('guid');

  useEffect(()=>{
    if(guid){
      setIsLoaded(true)
    }
    guid && TagService.getTagInfo(guid).then(response => {
      response.data.isPasswordSet?navigate('/?guid=' + guid):navigate('/register');
      dispatch(setTag(response.data));
      setIsLoaded(false);
     });
  },[])

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  
  const location = useLocation();

  useEffect(() => {

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);


  return (
      <div className={`container ${location.pathname !== '/login' ? "" : "login-screen"}`}>
        {isLoaded && <Loader/>}
        <Routes>
        <Route path="*" element={<NotFound/>} />
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/reset-password" element={<Reset/>} />
          <Route exact path="/register" element={<Register/>} />
          <Route exact path="/update" element={<Register/>} />
        </Routes>
      </div>

  );
};

export default App;
