import React, { useState, useRef } from "react";
import { Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

// import Button from '@mui/material/Button';
import { resetPassword } from "../../actions/auth";
import logo from "../../assets/images/logowhite.png";
import Card from "@mui/material/Card";

import './Reset.scss'


const Reset = (props) => {

  const [params] = useSearchParams()
  const navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const token = params.get('token')
  const email = params.get('email');
  const username = params.get('username');

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [requiredPassword, setRequiredPassword] = useState(false);
  const [ succesMessage, setSuccesMessage ] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);
  const message  = useSelector(state => state.message);

  const dispatch = useDispatch();


  const showSuccessMessage = () => {
    setSuccesMessage(succesMessage => !succesMessage);
  }
  
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    if(password===''){
      setRequiredPassword(true)
    }else{
      setRequiredPassword(false)
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(resetPassword(token, username, password, password))
        .then(() => {
          showSuccessMessage();
          setLoading(false);
          setTimeout(() => {
            navigate("/login");
          }, 3000)
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/home" replace={true} />;
  }

  return (
<div className="login reset">
     <div className="top-content">
        <div className="top-wrapper">
          <div className="logo-holder">
            <img src={logo} />
          </div>
          {/* <h1>{!forgotForm  ? "Login" : "Forgot password"}</h1> */}
        </div>
      </div>
      <div className="card-holder">
        <Card className="form-card">

        <Form onSubmit={handleResetPassword} ref={form}>
        
        <div className="form-group">
          <TextField
            type="password"
            className="form-field"
            name="password"
            value={password}
            onChange={onChangePassword}
            variant="outlined"
            fullWidth
            placeholder="Password"
            error={requiredPassword}
          />
          {requiredPassword &&<p className="error-text">Required field</p>}
        </div>
        <div className="form-group">
        <Button type="submit" className="custom-btn" disabled={loading} fullWidth variant="contained" id="btn-reset">
            {loading ? 
              <CircularProgress color="success"  style={{width: 20, height: 20, color: 'white'}}/>
            : <span>Change password</span>
            }
          </Button>
        </div>
        {message && (
          <div className="form-group">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )}
        {}
        <CheckButton style={{ display: "none" }} ref={checkBtn} />
      </Form>
         
      {succesMessage && 
          <p className="successMesage">
            Password reset succesfully completed
          </p>
        }
      
  
        </Card>
      </div>
   </div>
  );
};

export default Reset;
